<template>
  <div class="ant-pro-page-header-wrap" id="cleanerDashboard">
    <div class="ant-pro-grid-content dashboard">
      <monitor-template
        :isFullscreen="isFullscreen"
        class="map-style"
        ref="monitorRef"
        :mapPageParams="mapPageParams"
        @mapPageClick="handleMapPageClick"
      >
      </monitor-template>
      <div class="ant-pro-page-header-wrap-page-header-warp top-bar">
        <div class="top-bar-pad">
          <Screenfull
            targetElementId="cleanerDashboard"
            class="full-screen"
            iconColor="#00fff1"
            @isFullscreen="isFullscreen = $event"
          />
        </div>
        <div class="top-bar-center"></div>
        <div class="top-bar-pad">
          <a @click="handleSetup">设置</a>
        </div>
      </div>
    </div>

    <div class="dashboard-info" :class="[isFullscreen ? 'full' : '']">
      <!--智驾车辆-->
      <div class="dashboard-item" v-if="showAutoTodayForWorkOdom || showAutoVehicleStatis">
        <group-title title="智驾车辆统计" second-title="" />
        <div class="info-content">
          <animate-image
            prefix="miles"
            label="今日实时里程"
            :miles="autoTodayForWorkOdom"
            v-if="showAutoTodayForWorkOdom"
          />
          <vehicle-statis
            :items="autoVehicleVisibleStatisItems"
            v-if="showAutoVehicleStatis"
            :class="[showAutoTodayForWorkOdom ? '' : 'statis-padding', 'flex-1']"
          />
        </div>
      </div>
      <!--传统车辆-->
      <div class="dashboard-item" v-if="showTraditionTodayForWorkOdom || showTraditionVehicleStatis">
        <group-title title="传统车辆统计" second-title="" />
        <div class="info-content">
          <animate-image
            prefix="miles"
            label="今日实时里程"
            :miles="traditionTodayForWorkOdom"
            v-if="showTraditionTodayForWorkOdom"
          />
          <vehicle-statis
            :items="traVehicleVisibleStatisItems"
            v-if="showTraditionVehicleStatis"
            :class="[showTraditionTodayForWorkOdom ? '' : 'statis-padding', 'flex-1']"
          />
        </div>
      </div>
      <!--保洁人员工作状态-->
      <div class="dashboard-item" v-if="cleanerVisibleStatisItems && cleanerVisibleStatisItems.length > 0">
        <group-title title="保洁人员统计" second-title="" />
        <div class="info-content">
          <cleaner-statis :items="cleanerVisibleStatisItems" class="flex-1" />
        </div>
      </div>
      <!--保洁人员信息-->
      <div class="dashboard-item" v-if="showEmployeeRealLocation && currentDeviceType === 1 && currentDeviceId">
        <group-title title="当前保洁员信息" second-title="" />
        <div class="info-container info-content">
          <animate-image prefix="cleaner" :label="selectedCleanerInfo.bindName" :imageWidth="26" />
          <cleaner-info :item="selectedCleanerInfo" />
        </div>
      </div>
      <!--传统车辆信息-->
      <div class="dashboard-item" v-if="showTraditionVehicleRealLocation && currentDeviceType === 2 && currentDeviceId">
        <group-title title="当前传统车辆信息" second-title="" />
        <div class="info-container info-content">
          <animate-image prefix="vehicle" :label="selectedTraVehicleInfo.deviceId" :imageWidth="50" />
          <tra-vehicle-info :item="selectedTraVehicleInfo" />
        </div>
      </div>
      <!-- 智驾车辆信息 -->
      <div class="dashboard-item" v-if="showAutoVehicleRealLocation && currentDeviceType === 3 && currentDeviceId">
        <group-title title="当前智驾车辆信息" second-title="" />
        <div class="info-container info-content" style="padding-bottom: 10px">
          <animate-image prefix="vehicle" :label="selectedAutoVehicleInfo.vehicleId" :imageWidth="50" />
          <auto-vehicle-info :item="selectedAutoVehicleInfo" />
        </div>
        <play-video :vehicleItem="selectedAutoVehicleInfo" />
      </div>
    </div>
    <config ref="configRef" :cfg="cfg" @callback="handleCallback" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MonitorTemplate from '../../monitor/monitorTemplate'
import config from './light/config'
import GroupTitle from './light/group-title'
import AnimateImage from './light/animate-image'
import VehicleStatis from './light/vehicle-statis'
import CleanerStatis from './light/cleaner-statis'
import CleanerInfo from './light/cleaner-info'
import TraVehicleInfo from './light/tra-vehicle-info'
import AutoVehicleInfo from './light/auto-vehicle-info'
import Screenfull from '@/components/Screenfull'
import { queryDashboard } from '@/api/jt808/deviceStatus'
import { getDashboardConfig } from '@/api/system/config'
import coordinateTransform from '@/utils/coordinateTransform'
import { getDashboardAutoVehicleQuery, getAllVehicleInfo, getOneVehicleInfo } from '@/api/iot/vehicle'
import PlayVideo from './light/play-video'

export default {
  components: {
    MonitorTemplate,
    config,
    GroupTitle,
    AnimateImage,
    VehicleStatis,
    CleanerStatis,
    CleanerInfo,
    TraVehicleInfo,
    AutoVehicleInfo,
    Screenfull,
    PlayVideo
  },
  data() {
    return {
      // 大屏当前选中的对象信息
      dashboardSelectedObjectInfo: {},
      dashboardSelectedObjectKey: 'dashboardSelectedObjectKey',
      objectDelayUpdateInterVal: 5000,
      // 定时器是否处理完毕
      isTimerDone: true,
      // 最新对象节点
      recentObjectPointsObj: { isUpdate: true },
      // 保洁与传统车辆设备查询参数
      jtQueryParams: {
        employeeRealLocation: 'N',
        employeeSelectedNumber: 'N',
        employeeOnline: 'N',
        traditionVehicleRealLocation: 'N',
        traditionVehicleSelectedNumber: 'N',
        traditionVehicleOnline: 'N',
        traditionTodayForWorkOdom: 'N',
        traditionYesterdayForWorkOdom: 'N',
        employeeDeviceIds: [],
        traditionVehicleDeviceIds: []
      },
      cfg: null,
      isFullscreen: false,
      cleanerStatisItems: [
        {
          label: '当前选中的保洁人数',
          value: 0,
          src: require('@/assets/images/dashboard/ico-workers1-white.png'),
          key: 'employeeSelectedNumber',
          visible: true
        },
        {
          label: '其中在线的保洁人数',
          value: 0,
          src: require('@/assets/images/dashboard/ico-workers2-white.png'),
          key: 'employeeOnline',
          visible: true
        }
      ],

      traVehicleStatisItems: [
        { label: '当前选中的车辆', value: 0, key: 'traditionVehicleSelectedNumber', visible: true },
        { label: '其中在线的车辆', value: 0, bg: 'bg-online', key: 'traditionVehicleOnline', visible: true },
        { label: '昨日出勤总里程(KM)', value: 0, key: 'traditionYesterdayForWorkOdom', visible: true }
      ],

      autoVehicleStatisItems: [
        { label: '当前选中的车辆', value: 0, key: 'autoVehicleSelectedNumber', visible: true },
        { label: '其中在线的车辆', value: 0, bg: 'bg-online', key: 'autoVehicleOnline', visible: true },
        { label: '昨日出勤总里程(KM)', value: 0, key: 'autoYesterdayForWorkOdom', visible: true }
      ],

      traditionTodayForWorkOdom: 0,
      autoTodayForWorkOdom: 0,
      selectedCleanerInfo: {
        title: ''
      },
      // 传统车辆信息
      selectedTraVehicleInfo: {},
      // 智驾车辆信息
      selectedAutoVehicleInfo: {},
      // 当前设备类型，1保洁人员2传统车辆3智驾车辆
      currentDeviceType: 0,
      // 当前选中的设备ID
      currentDeviceId: '',
      cacheKey: 'cleanerDashboardTree',
      mode: 1,
      cleanerSwitch: {
        showRealLocation: true,
        showSelectedCount: true,
        showOnlineCount: true
      },
      traVehicleSwitch: {
        showRealLocation: true,
        showSelectedCount: true,
        showOnlineCount: true,
        showTodayMiles: true,
        showYestodayMiles: true
      },
      mapPageParams: {
        // 当marker位置发生变化时，是否需要将当前选中的marker移到地图中心
        isAutoSetMarkerToCenter: false,
        // 是否更新地图，当打开其它窗口时，则不更新地图
        isUpdateMap: true,
        showHeader: false,
        mapDelayUpdate: true,
        // 此配置控制车辆在地图上的实时分布
        mapDelayUpdateInterval: 1000,
        objectId: 'deviceId',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        // 地图数据源
        mapDataSource: 'real',
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        // 是否关闭地图弹窗
        isCloseMapInfoWindow: true,
        // marker 不同设备类型deviceType，对应不同的图标
        markerIconGroup: {
          1: {
            onlineIcon: {
              url: require('@/assets/images/dashboard/light/cleaner.png'),
              size: { width: 40, height: 43 },
              opts: {
                imageSize: {
                  width: 40,
                  height: 43
                }
              }
            },
            offlineIcon: {
              url: require('@/assets/images/dashboard/light/cleaner-off.png'),
              size: { width: 40, height: 43 },
              opts: {
                imageSize: {
                  width: 40,
                  height: 43
                }
              }
            }
          },
          2: {
            onlineIcon: {
              url: require('@/assets/images/dashboard/light/tra-vehicle-online.png'),
              size: { width: 53, height: 28 },
              opts: {
                imageSize: {
                  width: 53,
                  height: 28
                }
              }
            },
            offlineIcon: {
              url: require('@/assets/images/dashboard/light/tra-vehicle-offline.png'),
              size: { width: 53, height: 28 },
              opts: {
                imageSize: {
                  width: 53,
                  height: 28
                }
              }
            }
          },
          3: {
            onlineIcon: {
              url: require('@/assets/images/dashboard/light/vehicle-online.png'),
              size: { width: 53, height: 28 },
              opts: {
                imageSize: {
                  width: 53,
                  height: 28
                }
              }
            },
            offlineIcon: {
              url: require('@/assets/images/dashboard/light/vehicle-offline.png'),
              size: { width: 53, height: 28 },
              opts: {
                imageSize: {
                  width: 53,
                  height: 28
                }
              }
            }
          }
        },
        markerTypeFieldName: 'deviceType',
        unShowDistance: true,
        unShowTraffic: true,
        employeeDeviceIds: [],
        traditionVehicleDeviceIds: [],
        autoVehicleIds: []
      }
    }
  },
  computed: {
    ...mapState({
      sideCollapsed: (state) => state.app.sideCollapsed,
      allInfo: (state) => state.websocket.allInfo,
      websocketReady: (state) => state.websocket.websocketReady,
      allInfoReady: (state) => state.websocket.allInfoReady
    }),
    cleanerVisibleStatisItems() {
      return this.cleanerStatisItems.filter((p) => p.visible)
    },
    traVehicleVisibleStatisItems() {
      return this.traVehicleStatisItems.filter((p) => p.visible)
    },

    autoVehicleVisibleStatisItems() {
      return this.autoVehicleStatisItems.filter((p) => p.visible)
    },

    // 是否显示智驾统计
    showAutoVehicleStatis() {
      return this.autoVehicleStatisItems.filter((p) => p.visible).length > 0
    },
    // 是否显示传统车辆统计
    showTraditionVehicleStatis() {
      return this.traVehicleStatisItems.filter((p) => p.visible).length > 0
    },
    // 是否显示智驾今日里程
    showAutoTodayForWorkOdom() {
      return this.cfg ? this.cfg.autoTodayForWorkOdom === 'Y' : false
    },
    // 是否显示传统车辆今日里程
    showTraditionTodayForWorkOdom() {
      return this.cfg ? this.cfg.traditionTodayForWorkOdom === 'Y' : false
    },
    // 是否显示保洁人员实时位置
    showEmployeeRealLocation() {
      return this.cfg ? this.cfg.employeeRealLocation === 'Y' : false
    },
    // 是否显示传统车辆实时位置
    showTraditionVehicleRealLocation() {
      return this.cfg ? this.cfg.traditionVehicleRealLocation === 'Y' : false
    },
    // 是否显示智驾车辆实时位置
    showAutoVehicleRealLocation() {
      return this.cfg ? this.cfg.autoVehicleRealLocation === 'Y' : false
    }
  },
  watch: {
    sideCollapsed(newVal, oldVal) {
      console.log('resize')
      setTimeout(() => {
        this.resize++
      }, 500)
    },

    // ws返回的最新车辆列表
    allInfo(newVal, oldVal) {
      console.log('allinfo')
      const tmpArr = newVal.map((item) => {
        let lng = 0
        let lat = 0
        if (item.location && item.location.longitude > 0 && item.location.latitude > 0) {
          lng = item.location.longitude
          lat = item.location.latitude
        } else if (item.ins) {
          lng = item.ins.longitude
          lat = item.ins.latitude
        }
        if (lng > 0 && lat > 0) {
          try {
            const coo = coordinateTransform.wgs84togcj02tobd09(lng, lat)
            return { ...item, ...coo, rotation: item.location ? item.location.heading : 0 }
          } catch (error) {
            return { ...item, lng: 0, lat: 0 }
          }
        } else {
          return { ...item, lng: 0, lat: 0 }
        }
      })
      this.recentObjectPointsObj.isMapUpdate = false
      this.recentObjectPointsObj.data = tmpArr
    }
  },
  mounted() {
    // console.log('cleaner-dashboard')
    this.handleResize()
    this.getConfig(() => {
      this.getJtData()
    })
    this.initTimer()
    this.getCacheInfo()
  },
  methods: {
    getAllVehicleInfo(ids) {
      let selectedId = ''
      if (this.currentDeviceType === 3) {
        selectedId = this.currentDeviceId
      }
      if (!selectedId && ids.length > 0) {
        selectedId = ids[0]
      }

      const params = {
        vehicleIds: ids.join(','),
        selectVehicleId: selectedId
      }
      console.log('http获取所有节点数据')
      getAllVehicleInfo(params).then((res) => {
        // 通过ws请求数据
        console.log(`http获取所有节点数据成功`)
        this.mapPageParams.loadingWebsocket = false
        this.isGettingVehicleInfo = false
        this.$store
          .dispatch('websocketSubSend', {
            ids: ids,
            type: 'void',
            payload: {
              // 必需订阅
              mustSubs: true,
              type: 'all',
              data: res.data
            }
          })
          .then(() => {})
          .finally(() => {})
      })
    },
    // 更新数组对象值
    updateArrayValue(arr, key, value) {
      const obj = arr.find((p) => p.key === key)
      if (obj) {
        obj['value'] = value
      }
    },
    getCacheInfo() {
      const data = localStorage.getItem(this.dashboardSelectedObjectKey)
      if (data) {
        const currentSelectedInfo = JSON.parse(data)
        this.currentDeviceType = currentSelectedInfo.currentDeviceType
        this.currentDeviceId = currentSelectedInfo.currentDeviceId
        this.$refs.monitorRef.mapShowObjectInfo(this.currentDeviceId, false)
      }
    },
    getAutoStatis() {
      if (!this.cfg) {
        return
      }
      const params = {
        autoTodayForWorkOdom: this.cfg.autoTodayForWorkOdom,
        autoYesterdayForWorkOdom: this.cfg.autoYesterdayForWorkOdom,
        autoVehicleIds: this.cfg.autoVehicleIds
      }

      const hasConfig = params.autoTodayForWorkOdom === 'Y' || params.autoYesterdayForWorkOdom === 'Y'
      if (!hasConfig) {
        return
      }

      console.log('getAutoStatis', params)
      getDashboardAutoVehicleQuery(params).then((res) => {
        // console.log('getDashboardAutoVehicleQuery', res)
        const data = res.data
        if (data) {
          // 大于100则向上取整，否则向下取整
          const numArr = (data.autoTodayForWorkOdom / 1000).toFixed(3).split('.')
          const xs = Number('0.' + numArr[1])
          const zs = Number(numArr[0])
          const autoTodayForWorkOdom = xs >= 0.1 ? zs + 1 : zs
          this.autoTodayForWorkOdom = data.autoTodayForWorkOdom ? autoTodayForWorkOdom.toFixed(0) : 0
          // 更新昨天里程
          this.autoVehicleStatisItems.forEach((p) => {
            if (data[p.key] >= 0) {
              if (p.key === 'autoYesterdayForWorkOdom') {
                p.value = (data[p.key] / 1000).toFixed(1)
              }
            }
          })
          this.autoVehicleStatisItems = [...this.autoVehicleStatisItems]
          // console.log('autoVehicleStatisItems', this.autoVehicleStatisItems)
        }
      })
    },
    initTimer() {
      this.updateTimer = setInterval(() => {
        this.updateRecentObjectPoints()
        this.getJtData()
        this.getAutoStatis()
      }, this.objectDelayUpdateInterVal)
    },
    updateRecentObjectPoints() {
      // 判断任务是否执行完成
      if (this.isTimerDone) {
        this.isTimerDone = false
        try {
          if (this.recentObjectPointsObj.isMapUpdate === false) {
            this.recentObjectPointsObj.isMapUpdate = true
            // 更新数据
            // const data = this.recentObjectPointsObj.data
          }
        } finally {
          this.isTimerDone = true
        }
      }
    },

    async loadVehiclesMap(ids) {
      console.log('ids', ids)
      if (!this.websocketReady) {
        // 建立socket连接
        await this.$store.dispatch('websocketConnect')
      }

      this.getAllVehicleInfo(ids)
      // if (ids) {
      //   // 通过ws请求数据
      //   console.log('订阅车辆', ids)
      //   this.$store
      //     .dispatch('websocketSubSend', { ids })
      //     .then(() => {})
      //     .finally(() => {})
      // }
    },
    getConfig(fn) {
      // 读取设置信息
      getDashboardConfig({}).then((res) => {
        const data = res.data
        this.cfg = { ...data }
        this.cfg.employeeRealLocation = !this.cfg.employeeRealLocation ? 'N' : this.cfg.employeeRealLocation
        this.cfg.employeeSelectedNumber = !this.cfg.employeeSelectedNumber ? 'N' : this.cfg.employeeSelectedNumber
        this.cfg.employeeOnline = !this.cfg.employeeOnline ? 'N' : this.cfg.employeeOnline
        this.cfg.employeeDeviceIds = !this.cfg.employeeDeviceIds ? [] : this.cfg.employeeDeviceIds
        this.cfg.traditionVehicleRealLocation = !this.cfg.traditionVehicleRealLocation
          ? 'N'
          : this.cfg.traditionVehicleRealLocation
        this.cfg.traditionVehicleSelectedNumber = !this.cfg.traditionVehicleSelectedNumber
          ? 'N'
          : this.cfg.traditionVehicleSelectedNumber
        this.cfg.traditionVehicleOnline = !this.cfg.traditionVehicleOnline ? 'N' : this.cfg.traditionVehicleOnline
        this.cfg.traditionTodayForWorkOdom = !this.cfg.traditionTodayForWorkOdom
          ? 'N'
          : this.cfg.traditionTodayForWorkOdom
        this.cfg.traditionYesterdayForWorkOdom = !this.cfg.traditionYesterdayForWorkOdom
          ? 'N'
          : this.cfg.traditionYesterdayForWorkOdom
        this.cfg.traditionVehicleDeviceIds = !this.cfg.traditionVehicleDeviceIds
          ? []
          : this.cfg.traditionVehicleDeviceIds

        this.cfg.autoVehicleRealLocation = !this.cfg.autoVehicleRealLocation ? 'N' : this.cfg.autoVehicleRealLocation
        this.cfg.autoTodayForWorkOdom = !this.cfg.autoTodayForWorkOdom ? 'N' : this.cfg.autoTodayForWorkOdom
        this.cfg.autoYesterdayForWorkOdom = !this.cfg.autoYesterdayForWorkOdom ? 'N' : this.cfg.autoYesterdayForWorkOdom
        this.cfg.autoVehicleIds = !this.cfg.autoVehicleIds ? [] : this.cfg.autoVehicleIds
        this.mapPageParams.isAutoSetMarkerToCenter = this.cfg.autoAdjustMapCenterPoint === 'Y'
        this.cfg.employeeOnlineTime = !this.cfg.employeeOnlineTime ? 10 : this.cfg.employeeOnlineTime

        if (data.autoVehicleIds) {
          this.disconnectSocket()
          setTimeout(() => {
            this.loadVehiclesMap(data.autoVehicleIds)
          }, 500)
        }
        const params = {
          employeeRealLocation: 'N',
          employeeSelectedNumber: 'N',
          employeeOnline: 'N',
          traditionVehicleRealLocation: 'N',
          traditionVehicleSelectedNumber: 'N',
          traditionVehicleOnline: 'N',
          traditionTodayForWorkOdom: 'N',
          traditionYesterdayForWorkOdom: 'N',
          employeeDeviceIds: [],
          traditionVehicleDeviceIds: []
        }
        Object.keys(params).forEach((p) => {
          if (data[p]) {
            params[p] = data[p]
          }
        })

        this.jtQueryParams = params
        if (this.cfg) {
          // 智驾车辆统计项目
          this.autoVehicleStatisItems.forEach((p) => {
            p.visible = this.cfg[p.key] === 'Y'
          })
          this.autoVehicleStatisItems = [...this.autoVehicleStatisItems]

          // 保洁统计项目
          this.cleanerStatisItems.forEach((p) => {
            p.visible = this.cfg[p.key] === 'Y'
          })
          this.cleanerStatisItems = [...this.cleanerStatisItems]

          // 传统车辆统计
          this.traVehicleStatisItems.forEach((p) => {
            p.visible = this.cfg[p.key] === 'Y'
          })
          this.traVehicleStatisItems = [...this.traVehicleStatisItems]
          // 更新当前选中车辆
          const num = this.cfg.autoVehicleIds ? this.cfg.autoVehicleIds.length : 0
          this.updateArrayValue(this.autoVehicleStatisItems, 'autoVehicleSelectedNumber', num)
        }
        fn && fn()
      })
    },
    checkHaveConfig(obj) {},
    getJtData() {
      if (
        [1, 2].indexOf(this.currentDeviceType) >= 0 &&
        this.currentDeviceId &&
        this.jtQueryParams.employeeRealLocation === 'Y'
      ) {
        // deviceIds用于计算当前选中人员的当日步行距离
        this.jtQueryParams.deviceIds = [this.currentDeviceId]
      } else {
        if (this.jtQueryParams.deviceIds) {
          delete this.jtQueryParams.deviceIds
        }
      }
      const config = this.jtQueryParams
      const hasConfig =
        config.employeeRealLocation === 'Y' ||
        config.employeeSelectedNumber === 'Y' ||
        config.employeeOnline === 'Y' ||
        config.traditionVehicleRealLocation === 'Y' ||
        config.traditionVehicleSelectedNumber === 'Y' ||
        config.traditionVehicleOnline === 'Y' ||
        config.traditionTodayForWorkOdom === 'Y' ||
        config.traditionYesterdayForWorkOdom === 'Y'
      if (!hasConfig) {
        if (this.cfg && this.cfg.autoVehicleRealLocation === 'Y') {
          this.updateMapObjectLocation()
        }
        return
      }
      queryDashboard(this.jtQueryParams).then((res) => {
        // console.log('queryDashboard')
        const data = res.data
        // 1.1保洁人员位置信息
        let cleanerList = data.employeeRealLocationList ? data.employeeRealLocationList : []
        if (cleanerList) {
          let onlineNum = 0
          cleanerList = cleanerList.map((p) => {
            const deviceTime = p.deviceTime
            if (deviceTime) {
              const lastTime = new Date(deviceTime).getTime()
              const nowTime = new Date().getTime()
              const onlineMin = Math.ceil((nowTime - lastTime) / 1000 / 60)
              const employeeOnlineTime = this.cfg.employeeOnlineTime ? this.cfg.employeeOnlineTime : 10
              p.online = onlineMin <= employeeOnlineTime ? 1 : 0
            } else {
              p.online = 0
            }
            if (p.online) {
              onlineNum++
            }
            //
            p.deviceType = 1
            return p
          })

          if (this.currentDeviceType === 1 && this.currentDeviceId) {
            this.selectedCleanerInfo = cleanerList.find((p) => p.deviceId === this.currentDeviceId)
            if (!this.selectedCleanerInfo) {
              this.currentDeviceType = 0
              this.currentDeviceId = 0
            }
          }
          data.employeeOnline = onlineNum
        }
        // 1.2 保洁人员统计信息
        this.cleanerStatisItems.forEach((p) => {
          if (data[p.key] >= 0) {
            p.value = data[p.key]
          }
        })
        // 1.3重新渲染
        this.cleanerStatisItems = [...this.cleanerStatisItems]
        // 2.1传统车辆
        let traVehicleList = data.traditionVehicleRealLocationList ? data.traditionVehicleRealLocationList : []
        if (traVehicleList) {
          traVehicleList = traVehicleList.map((p, idx) => {
            p.deviceType = 2
            p.rotation = p.direction
            return p
          })

          if (this.currentDeviceType === 2 && this.currentDeviceId) {
            this.selectedTraVehicleInfo = traVehicleList.find((p) => p.deviceId === this.currentDeviceId)
            if (!this.selectedTraVehicleInfo) {
              this.currentDeviceType = 0
              this.currentDeviceId = 0
            }
          }
        }
        // 2.2 传统车辆统计信息
        this.traVehicleStatisItems.forEach((p) => {
          if (data[p.key] >= 0) {
            if (['traditionYesterdayForWorkOdom'].indexOf(p.key) >= 0) {
              p.value = (data[p.key] / 1000).toFixed(1)
            } else {
              p.value = data[p.key]
            }
          }
        })

        this.traVehicleStatisItems = [...this.traVehicleStatisItems]
        if (data.traditionTodayForWorkOdom >= 0) {
          const numArr = (data.traditionTodayForWorkOdom / 1000).toFixed(3).split('.')
          const xs = Number('0.' + numArr[1])
          const zs = Number(numArr[0])
          const traditionTodayForWorkOdom = xs >= 0.1 ? zs + 1 : zs
          this.traditionTodayForWorkOdom = traditionTodayForWorkOdom.toFixed(0)
        }

        // 3.1 智驾位置数据
        this.updateMapObjectLocation(cleanerList, traVehicleList)
      })
    },

    updateMapObjectLocation(cleanerList, traVehicleList) {
      console.log('updateMapObjectLocation')
      // 智驾位置数据
      const vData = this.recentObjectPointsObj.data
      const autoLocationData = []
      if (vData && vData.length > 0) {
        vData.forEach((p) => {
          if (p.lat > 0 && p.lng > 0) {
            autoLocationData.push({
              deviceType: 3,
              lat: p.lat,
              lng: p.lng,
              deviceId: p.vehicleId,
              speed: 0,
              online: p.online ? 1 : 0,
              rotation: p.rotation
            })
          }
        })

        if (this.currentDeviceType === 3) {
          this.selectedAutoVehicleInfo = vData.find((p) => p.vehicleId === this.currentDeviceId)
          if (!this.selectedAutoVehicleInfo) {
            this.currentDeviceType = 0
            this.currentDeviceId = 0
          }
        }
        this.updateArrayValue(this.autoVehicleStatisItems, 'autoVehicleOnline', vData.filter((p) => p.online).length)
      }

      let destData = []
      if (this.cfg.employeeRealLocation === 'Y' && cleanerList) {
        destData = [...destData, ...cleanerList]
      }

      if (this.cfg.traditionVehicleRealLocation === 'Y' && traVehicleList) {
        destData = [...destData, ...traVehicleList]
      }

      if (this.cfg.autoVehicleRealLocation === 'Y') {
        destData = [...destData, ...autoLocationData]
      }

      if (destData.length === 0) {
        return
      }

      this.recentObjectPoints = destData.map((p) => {
        return {
          ...p,
          key: p.deviceType,
          lat: p.coordinates ? p.coordinates[1] : p.lat,
          lng: p.coordinates ? p.coordinates[0] : p.lng,
          speed: Number(p.speed) / 10
        }
      })

      setTimeout(() => {
        this.$refs.monitorRef && this.$refs.monitorRef.mapUpdateObjectPoints(this.recentObjectPoints)
      }, 1000)
    },

    handleSetup() {
      // console.log('handleSetup')
      this.$refs.configRef.handleSetup()
    },
    handleResize() {
      window.onresize = () => {
        this.resize++
        console.log('resize')
      }
    },
    handleMapPageClick(eventName, eventArgs) {
      if (eventName === 'selectObjectInfo') {
        // 地图上选中物体
        this.objectInfo = { ...eventArgs }
        // console.log('选中对象', this.objectInfo)
        if (this.objectInfo) {
          this.currentDeviceType = this.objectInfo.deviceType
          this.currentDeviceId = this.objectInfo.deviceId
          // 保存在本地缓存
          localStorage.setItem(
            this.dashboardSelectedObjectKey,
            JSON.stringify({
              currentDeviceType: this.currentDeviceType,
              currentDeviceId: this.currentDeviceId
            })
          )
          if (this.currentDeviceType === 1) {
            this.selectedCleanerInfo = { ...this.objectInfo }
          } else if (this.currentDeviceType === 2) {
            this.selectedTraVehicleInfo = { ...this.objectInfo }
          } else if (this.currentDeviceType === 3) {
            const data = this.recentObjectPointsObj.data
            const id = this.objectInfo.deviceId
            if (data && id) {
              this.selectedAutoVehicleInfo = data.find((p) => p.vehicleId === id)
              if (this.selectedCleanerInfo && !this.selectedCleanerInfo.withAllInfo) {
                this.subscribeManyVehicle([id])
              }
            }
          }
        }
      }
    },

    // 订阅多辆车
    subscribeManyVehicle(ids, callback) {
      this.mapPageParams.loadingWebsocket = true
      this.isGettingVehicleInfo = true
      console.log(`http获取选中车辆全量数据,vehicleIds=${ids}`)
      getOneVehicleInfo({
        vehicleIds: ids.join(',')
      }).then((res) => {
        this.mapPageParams.loadingWebsocket = false
        this.isGettingVehicleInfo = false
        console.log(`http获取所有节点数据成功`)
        this.$store.dispatch('websocketSubSend', {
          payload: {
            // 不需要订阅
            mustSubs: false,
            type: 'manyVehicle',
            data: res.data
          }
        })
        if (callback) {
          setTimeout(() => {
            callback()
          }, 0)
        }
      })
    },

    handleCallback(localConfig) {
      this.getConfig()
    },

    handleCallback2(localConfig) {
      this.cleanerSwitch = Object.assign(this.cleanerSwitch, localConfig.cleanerSwitch)
      this.traVehicleSwitch = Object.assign(this.traVehicleSwitch, localConfig.traVehicleSwitch)
      // 构建新的请求参数
      // 如果不显示实时位置，则接口请求参数就不传入
      // 重新请求一次接口
      // UI更新
    },
    // 断开socket
    disconnectSocket() {
      this.$store.dispatch('stopWebSocket')
    }
  },
  beforeDestroy() {
    this.updateTimer && clearInterval(this.updateTimer)
    this.disconnectSocket()
  }
}
</script>
<style lang="less" scoped>
.dashboard {
  position: relative;
}
.map-style {
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}

.top-bar {
  position: absolute !important;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
}

.top-bar-center {
  height: 100%;
  width: 1024px;
  background-image: url('../../../assets/images/dashboard/header-bg-white.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  z-index: 100;
}

.top-bar-pad {
  height: 100%;
  flex: 1;
  background-image: url('../../../assets/images/dashboard/header-pad-white.png');
  background-repeat: repeat;
  background-position: center;
  background-size: auto 100%;
  z-index: 100;
  a {
    position: absolute;
    top: -1px;
    right: 10px;
    color: #00fff1;
    display: block;
    padding-left: 20px;
    width: 60px;
    background: url('../../../assets/images/dashboard/ico-setting-white.png') no-repeat left center;
    background-size: 16px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
}

.info-container {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  > :first-child {
    margin-left: 30px;
    margin-right: 30px;
  }
  > :last-child {
    flex: 1;
  }
}

.dashboard-info {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 370px;
  position: absolute;
  top: 74px;
  right: 0px;
  .dashboard-item {
    margin-top: 0px;
  }
  .info-content {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: saturate(180%) blur(15px);
    -moz-backdrop-filter: saturate(180%) blur(15px);
    backdrop-filter: saturate(180%) blur(15px);
    padding-bottom: 20px;
    padding-top: 20px;
    .statis-padding {
      padding-left: 40px;
      padding-right: 20px;
    }
    .flex-1 {
      flex: 1;
    }
  }
}
.full {
  top: 55px;
  right: 24px;
}

.full-screen {
  padding-top: 3px;
  padding-left: 10px;
}

/deep/ .ant-pro-page-header-wrap-page-header-warp {
  background-color: rgba(0, 0, 0, 0);
}
</style>
