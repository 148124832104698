var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ant-pro-page-header-wrap",
      attrs: { id: "cleanerDashboard" },
    },
    [
      _c(
        "div",
        { staticClass: "ant-pro-grid-content dashboard" },
        [
          _c("monitor-template", {
            ref: "monitorRef",
            staticClass: "map-style",
            attrs: {
              isFullscreen: _vm.isFullscreen,
              mapPageParams: _vm.mapPageParams,
            },
            on: { mapPageClick: _vm.handleMapPageClick },
          }),
          _c(
            "div",
            {
              staticClass: "ant-pro-page-header-wrap-page-header-warp top-bar",
            },
            [
              _c(
                "div",
                { staticClass: "top-bar-pad" },
                [
                  _c("Screenfull", {
                    staticClass: "full-screen",
                    attrs: {
                      targetElementId: "cleanerDashboard",
                      iconColor: "#00fff1",
                    },
                    on: {
                      isFullscreen: function ($event) {
                        _vm.isFullscreen = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "top-bar-center" }),
              _c("div", { staticClass: "top-bar-pad" }, [
                _c("a", { on: { click: _vm.handleSetup } }, [_vm._v("设置")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dashboard-info",
          class: [_vm.isFullscreen ? "full" : ""],
        },
        [
          _vm.showAutoTodayForWorkOdom || _vm.showAutoVehicleStatis
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "智驾车辆统计", "second-title": "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "info-content" },
                    [
                      _vm.showAutoTodayForWorkOdom
                        ? _c("animate-image", {
                            attrs: {
                              prefix: "miles",
                              label: "今日实时里程",
                              miles: _vm.autoTodayForWorkOdom,
                            },
                          })
                        : _vm._e(),
                      _vm.showAutoVehicleStatis
                        ? _c("vehicle-statis", {
                            class: [
                              _vm.showAutoTodayForWorkOdom
                                ? ""
                                : "statis-padding",
                              "flex-1",
                            ],
                            attrs: { items: _vm.autoVehicleVisibleStatisItems },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showTraditionTodayForWorkOdom || _vm.showTraditionVehicleStatis
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "传统车辆统计", "second-title": "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "info-content" },
                    [
                      _vm.showTraditionTodayForWorkOdom
                        ? _c("animate-image", {
                            attrs: {
                              prefix: "miles",
                              label: "今日实时里程",
                              miles: _vm.traditionTodayForWorkOdom,
                            },
                          })
                        : _vm._e(),
                      _vm.showTraditionVehicleStatis
                        ? _c("vehicle-statis", {
                            class: [
                              _vm.showTraditionTodayForWorkOdom
                                ? ""
                                : "statis-padding",
                              "flex-1",
                            ],
                            attrs: { items: _vm.traVehicleVisibleStatisItems },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.cleanerVisibleStatisItems &&
          _vm.cleanerVisibleStatisItems.length > 0
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "保洁人员统计", "second-title": "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "info-content" },
                    [
                      _c("cleaner-statis", {
                        staticClass: "flex-1",
                        attrs: { items: _vm.cleanerVisibleStatisItems },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showEmployeeRealLocation &&
          _vm.currentDeviceType === 1 &&
          _vm.currentDeviceId
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "当前保洁员信息", "second-title": "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "info-container info-content" },
                    [
                      _c("animate-image", {
                        attrs: {
                          prefix: "cleaner",
                          label: _vm.selectedCleanerInfo.bindName,
                          imageWidth: 26,
                        },
                      }),
                      _c("cleaner-info", {
                        attrs: { item: _vm.selectedCleanerInfo },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showTraditionVehicleRealLocation &&
          _vm.currentDeviceType === 2 &&
          _vm.currentDeviceId
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "当前传统车辆信息", "second-title": "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "info-container info-content" },
                    [
                      _c("animate-image", {
                        attrs: {
                          prefix: "vehicle",
                          label: _vm.selectedTraVehicleInfo.deviceId,
                          imageWidth: 50,
                        },
                      }),
                      _c("tra-vehicle-info", {
                        attrs: { item: _vm.selectedTraVehicleInfo },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showAutoVehicleRealLocation &&
          _vm.currentDeviceType === 3 &&
          _vm.currentDeviceId
            ? _c(
                "div",
                { staticClass: "dashboard-item" },
                [
                  _c("group-title", {
                    attrs: { title: "当前智驾车辆信息", "second-title": "" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "info-container info-content",
                      staticStyle: { "padding-bottom": "10px" },
                    },
                    [
                      _c("animate-image", {
                        attrs: {
                          prefix: "vehicle",
                          label: _vm.selectedAutoVehicleInfo.vehicleId,
                          imageWidth: 50,
                        },
                      }),
                      _c("auto-vehicle-info", {
                        attrs: { item: _vm.selectedAutoVehicleInfo },
                      }),
                    ],
                    1
                  ),
                  _c("play-video", {
                    attrs: { vehicleItem: _vm.selectedAutoVehicleInfo },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("config", {
        ref: "configRef",
        attrs: { cfg: _vm.cfg },
        on: { callback: _vm.handleCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }